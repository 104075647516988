<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage:salesOrderFilter.limit
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'number'">
          <router-link :to="{ name: 'salesorder-detail', params: { id: props.row.id } }">
            <h6 class="text-primary m-0">
              {{ props.row.number ? props.row.number : 'N/A' }}
            </h6>
            <small class="text-muted">
              {{ props.row.poNumber }}
            </small>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'transDate'">
          <div v-if="props.row.transDate">
            <small class="text-muted">
              Tanggal Transaksi :
            </small>
            <h6 class="m-0 mb-25">
              {{ props.row.transDate }}
            </h6>
          </div>
          <div v-if="props.row.shipDate">
            <small class="text-muted">
              Tanggal Pengiriman :
            </small>
            <h6 class="m-0">
              {{ props.row.shipDate }}
            </h6>
          </div>
        </span>
        <span v-else-if="props.column.field === 'customer'">
          <div class="d-flex align-items-center">
            <b-avatar
              :text="avatarText(props.row.customer.name)"
              variant="light-primary"
              class="mr-50"
            />
            <router-link :to="{ name: 'customer-detail', params: {id: props.row.customer.id.toString()} }">
              <h6 class="text-primary m-0">{{ props.row.customer.name }}</h6>
            </router-link>
          </div>
        </span>
        <span v-else-if="props.column.field === 'description'">
          <h6 class="font-weight-normal m-0">
            {{ props.row.description }}
          </h6>
        </span>
        <span v-else-if="props.column.field === 'shipment'">
          <h6
            v-if="props.row.shipment"
            class="font-weight-normal m-0"
          >
            {{ props.row.shipment.name }}
          </h6>
        </span>
        <span v-else-if="props.column.field === 'userCreate'">
          <b-media
            v-if="props.row.userCreate"
            class="mt-25"
          >
            <template #aside>
              <b-avatar
                size="36"
                :text="avatarText( props.row.userCreate.name )"
                variant="light-primary"
                :src="props.row.userCreate.photo && props.row.userCreate.photo !== 'default.png' ?
                  `${$restHTTP}/assets/img/profile-picture/${props.row.userCreate.id}/${props.row.userCreate.photo}` :
                  `${$restHTTP}/assets/img/profile-picture/default.png`
                "
              />
            </template>
            <router-link :to="{ name: 'user-detail', params: {id: props.row.userCreate.id.toString()} }">
              <h6 class="text-primary m-0">{{ props.row.userCreate.name }}</h6>
            </router-link>
            <small
              v-if="props.row.userCreate.role"
              class="d-block text-muted"
            >
              {{ props.row.userCreate.role.name }}
            </small>
          </b-media>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="getStatusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'subTotal'">
          <h6 class="m-0">
            {{ formatCurrency(props.row.totalAmount) }}
          </h6>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'salesorder-detail', params: { id: props.row.id } }"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50 text-primary"
                />
                <span class="text-primary">Lihat Detail</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                :disabled="!resolveUserPermission(props.row.userCreate)"
                @click="resolveUserPermission(props.row.userCreate) ? $router.push({ name: 'salesorder-edit', params: {id: props.row.id} }) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50 mb-25"
                  :class="resolveUserPermission(props.row.userCreate) ? 'text-warning' : 'text-secondary'"
                />
                <span :class="resolveUserPermission(props.row.userCreate) ? 'text-warning' : 'text-secondary'">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!resolveUserPermission(props.row.userCreate)"
                @click="confirmDelete(props.row.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50 mb-25"
                  :class="resolveUserPermission(props.row.userCreate) ? 'text-danger' : 'text-secondary'"
                />
                <span :class="resolveUserPermission(props.row.userCreate) ? 'text-danger' : 'text-secondary'">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="salesOrderFilter.limit"
              :options="[25, 50, 75, 100]"
              class="mx-1"
            />
            <span class="text-nowrap "> dari total {{ count }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="salesOrderFilter.offset"
              :total-rows="count"
              :per-page="salesOrderFilter.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormSelect, BPagination, BDropdown, BDropdownItem, BBadge, BAvatar, VBPopover, BMedia, BDropdownDivider,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText, shortNumber, formatCurrency } from '@core/utils/filter'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getSalesOrderField',
  mutationType: 'updateSalesOrderField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    BMedia,
    BDropdownDivider,
    VueGoodTable,
    EmptyStateTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarText,
      shortNumber,
      formatCurrency,
      columns: [
        {
          field: 'number',
          label: 'Nomor',
          sortable: false,
          tdClass: 'w-vgt-240',
        },
        {
          field: 'transDate',
          label: 'Tanggal',
          sortable: false,
          width: '160px',
        },
        {
          field: 'customer',
          label: 'Customer',
          sortable: false,
          tdClass: 'w-vgt-240',
          hidden: this.isCustomer,
        },
        {
          field: 'description',
          label: 'Keterangan',
          sortable: false,
          tdClass: 'w-vgt-200',
        },
        {
          field: 'shipment',
          label: 'Nama Pengiriman',
          sortable: false,
          tdClass: 'w-vgt-200',
        },
        {
          field: 'userCreate',
          label: 'Dibuat Oleh',
          sortable: false,
          tdClass: 'w-vgt-200',
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          field: 'subTotal',
          label: 'Total',
          sortable: false,
          width: '140px',
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'salesOrderFilter',
      'statusOptions',
    ]),
  },
  mounted() {
    this.$refs.vgt.currentPage = this.salesOrderFilter.offset
  },
  methods: {
    getStatusVariant(status) {
      const filter = this.statusOptions.filter(el => el.label === status)

      if (!filter.length) {
        return 'secondary'
      }

      return filter[0].variant
    },
    resolveUserPermission(userCreate) {
      return (this.$root.role === 2 || (userCreate && userCreate.id === this.$store.getters.getCurrentUser.user.id))
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Hapus Sales Order?',
        text: 'Konfirmasi jika anda ingin menghapus data sales order',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteSalesOrder', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data sales order!',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })

            this.$emit('refetch')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body.dark-layout .badge {
  color: #fff !important;
}
</style>
