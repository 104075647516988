<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row class="mb-50">
            <b-col
              cols="12"
              xl="3"
              class="mb-1"
            >
              <v-select
                v-model="salesOrderFilter.selectedSort"
                class="mr-1 w-100"
                label="label"
                :options="salesOrderSortOptions"
                placeholder="Sortir"
                :clearable="false"
                @option:selected="fetchSalesOrder()"
              />
            </b-col>
            <b-col
              cols="12"
              xl="3"
              class="mb-1 pl-1 pl-xl-0"
            >
              <b-form-input
                v-model="salesOrderFilter.search"
                class="w-100"
                placeholder="Cari data"
                debounce="500"
              />
            </b-col>
            <b-col
              cols="12"
              xl="5"
              md="12"
              class="d-flex ml-0 ml-xl-auto align-items-start mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-toggle.sidebar.sidebar-right
                variant="outline-primary"
                class="mr-1"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'salesorder-add' }"
                variant="primary"
                class="flex-grow-1"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Sales Order
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-1"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay
            :show="loadingSalesOrder"
          >
            <sales-order-table
              :key="salesOrderList.toString()"
              :data="salesOrderList"
              :count="salesOrderCount"
              @refetch="fetchSalesOrder()"
            />
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      title="Filter"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      lazy
      @hidden="fetchSalesOrder()"
    >
      <div class="px-2 py-1">
        <div class="d-flex justify-content-between align-items-end">
          <label>Tanggal transaksi</label>
          <feather-icon
            v-if="salesOrderFilter.tanggal_transaksi.startDate ? true : false"
            :key="key"
            size="16"
            icon="XCircleIcon"
            class="text-primary cursor-pointer"
            @click="salesOrderFilter.tanggal_transaksi = {}; $refs.dateRange.reset()"
          />
        </div>
        <date-range-picker
          ref="dateRange"
          v-model="salesOrderFilter.tanggal_transaksi"
          :wrap="true"
          class="mb-1"
        />
        <label>Customer</label>
        <v-select
          v-model="salesOrderFilter.customer"
          class="w-100 mb-1"
          label="name"
          :options="customer"
          placeholder="Cutomer"
          @search="searchCustomer"
        >
          <template #no-options>
            <h6 class="text-muted font-weight-normal my-50">
              Tidak ada customer yang ditemukan
            </h6>
          </template>
          <template #option="data">
            <span class="font-weight-bold d-block mt-25">{{ data.name }}</span>
            <small>
              <b-badge
                variant="light-info"
                class="mb-25"
              >
                {{ data.category }}
              </b-badge>
            </small>
          </template>
        </v-select>
        <label>Status</label>
        <v-select
          v-model="salesOrderFilter.status"
          class="w-100 mb-1"
          :options="statusOptions"
          placeholder="Status"
        />
      </div>
      <template #footer>
        <div class="px-2 py-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="secondary"
            @click="resetFilter(); $refs.sidebarRight.hide()"
          >
            Reset
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BRow, BCol, BOverlay, BSidebar, VBToggle, BBadge,
} from 'bootstrap-vue'
import SalesOrderTable from '@/views/salesorder/SalesOrderTable.vue'
import vSelect from 'vue-select'
import { createHelpers } from 'vuex-map-fields'
import DateRangePicker from '@/components/input/DateRangePicker.vue'

const { mapFields } = createHelpers({
  getterType: 'getSalesOrderField',
  mutationType: 'updateSalesOrderField',
})

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    SalesOrderTable,
    BRow,
    BCol,
    BOverlay,
    BSidebar,
    BBadge,
    vSelect,
    DateRangePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      customer: [],
    }
  },
  computed: {
    ...mapFields([
      'loadingSalesOrder',
      'salesOrderCount',
      'salesOrderList',
      'salesOrderFilter',
      'salesOrderSortOptions',
      'statusOptions',
    ]),
    filterMode() {
      if (this.salesOrderFilter.customer) return true
      if (this.salesOrderFilter.search) return true
      if (this.salesOrderFilter.selectedSort.field !== 'transDate' || this.salesOrderFilter.selectedSort.sortType !== 'desc') return true
      if (this.salesOrderFilter.tanggal_transaksi.startDate) return true
      if (this.salesOrderFilter.status) return true
      return false
    },
  },
  watch: {
    salesOrderFilter: {
      handler() {
        if (!this.$refs.sidebarRight.isOpen) {
          this.fetchSalesOrder()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.salesOrderList.length < 1) {
      setTimeout(() => {
        this.fetchSalesOrder()
      }, 1000)
    }
  },
  methods: {
    resetFilter() {
      this.salesOrderFilter = {
        search: '',
        selectedSort: {
          label: 'Tanggal Transaksi ↓',
          field: 'transDate',
          sortType: 'desc',
        },
        offset: 1,
        limit: 25,
        customer: '',
        status: '',
        tanggal_transaksi: {},
      }

      this.fetchSalesOrder()
    },
    async fetchSalesOrder() {
      const search = this.salesOrderFilter.search ? `"${this.salesOrderFilter.search}"` : null
      const customer = this.salesOrderFilter.customer ? +this.salesOrderFilter.customer.id : null
      const transDate = this.salesOrderFilter.tanggal_transaksi.startDate ? `{
        date_min: "${new Date(Date.parse(this.salesOrderFilter.tanggal_transaksi.startDate)).toISOString().slice(0, 10)}"
        date_max: "${new Date(Date.parse(this.salesOrderFilter.tanggal_transaksi.endDate)).toISOString().slice(0, 10)}"
      }` : null
      const status = this.salesOrderFilter.status ? `"${this.salesOrderFilter.status.label}"` : null

      this.$store.dispatch('getSalesOrder', {
        promise: false,
        filter: {
          sort: this.salesOrderFilter.selectedSort.field,
          sortType: this.salesOrderFilter.selectedSort.sortType,
          search,
          customer_id: customer,
          trans_date: transDate,
          status,
        },
        pagination: {
          limit: this.salesOrderFilter.limit,
          offset: this.salesOrderFilter.offset,
        },
      })
    },
    searchCustomer(search, loading) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(async () => {
        if (search.length) {
          loading(true)
          this.$store.dispatch('getCustomers', {
            filter: `{
              search: "${search}",
            }`,
            pagination: `{
              limit: 20,
              offset: 1,
            }`,
          }).then(result => {
            this.customer = result.customers
            loading(false)
          }).catch(() => {
            this.customer = []
            loading(false)
          })
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}

.form-control {
  .dark-layout & {
    color: #fff !important;
  }
}
</style>
